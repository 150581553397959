body {
    background: url(./Media/bg_flowers.png);
    background-size: 100%;
}

@font-face {
    font-family: candaraFont;
    src: url(./Font/Candara.ttf);
}

h1, h2, h3, h4, h5, h6, p, span, button, .btn, a, label, input {
    font-family: candaraFont;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.logo_with_border_design {
    height: 225px;
    z-index: 9;
}

.top_design {
    width: 100%;
    vertical-align: unset;
}

.section_navbar {
    margin-top: 15px;
}

.navbar {
    /* top: 60px; */
    transition: top .5s;
    height: 60px;
    background: #fff0f0;
    z-index: 9;
}

.img_banner {
    width: 100%;
    height: auto;
    margin-top: 1.5px;
}

/* .section_banner_slider{
    height: 82vh;
} */

.section_banner_slider .slick-arrow {
    display: none !important;
}

.section_banner_slider .slick-list {
    position: relative;
}

.section_banner_slider .slick-dots {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: auto !important;
    top: 50%;
    left: 48%;
}

.section_banner_slider .slick-dots li button::before {
    font-size: 20px !important;
    color: #fff !important;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
}

.section_banner_slider .slick-vertical .slick-slide {
    border: unset !important;
}

.div_banner_slick_parent {
    position: relative;
    text-align: center;
    color: white;
}

.div_text_on_banner_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #00000073;
    border-radius: 20px;
}

.div_registration_banner_parent {
    position: relative;
    text-align: center;
    color: white;
}

.div_text_on_registration_banner_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #00000080;
    border-radius: 20px;
    padding: 15px;
}

.logo_on_slider {
    width: 40%;
    margin: 0 auto;
}

.text_on_banner_slider {
    color: white;
    font-weight: bold;
    font-size: 3rem;
    text-shadow: 0 0 5px black;
}

.h_tag_heading, .p_tag_services, .p_tag_video_gallery, .p_tag_photo_gallery {
    color: #ee2642;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.p_tag_services {
    height: 50px;
}

.heading_design_right, .heading_design_left {
    height: 25px;
}

.img_services {
    width: 90%;
    height: 250px;
    margin: 0 auto;
    display: inline-block;
    object-fit: cover;
}

.section_our_services .slick-arrow {
    filter: invert(50%);
    top: 45%;
}

.section_our_services .slick-arrow::before {
    font-size: 30px;
}

.div_heading {
    padding: 20px 0;
}

.section_video_gallery, .section_vendor_registration {
    background: #fcf3f6;
}

.img_video_gallery {
    display: inline-block;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: filter 0.2s ease;
}

.div_video_gallery_col {
    position: relative;
}

.youtube_icon_center {
    font-size: 50px;
    color: red;
    cursor: pointer;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    transition: filter 0.3s ease;
}


/* .img_video_gallery:hover, .youtube_icon_center:hover {
    color: rgb(180, 3, 3);
} */

.div_video_gallery_col:hover .img_video_gallery {
    filter: blur(2px);
}

.div_video_gallery_col:hover .youtube_icon_center {
    filter: drop-shadow(0px 0px 5px black);
}

.section_video_gallery svg {
    filter: drop-shadow(0px 0px 5px rgb(0 0 0 / 0.4));
}

.venue_location {
    width: 100%;
    height: 85vh;
}

.section_location {
    margin-bottom: -5px;
}

.section_footer {
    background: #333;
}

.footer_logo {
    height: 100px;
    margin: 20px;
}

.modal_logo {
    height: 75px;
    margin: 20px;
}

.div_footer_header {
    height: 100px;
    /* height: 50px; */
    display: table;
    margin: 20px;
}

.h_tag_footer_header {
    display: table-cell;
    vertical-align: middle;
}

.img_photo_gallery {
    display: inline-block;
    width: 75%;
}

.div_img_photo_gallery {
    position: relative;
    text-align: center;
}

.img_photo_gallery_border {
    position: absolute;
    top: -20px;
    left: 7px;
    width: 100px;
}

.btn_social_icons {
    line-height: 0 !important;
    border: thick #fcf3f6 !important;
    padding: 10px !important;
}

.btn_social_icons:hover {
    background-color: #ee8f9b !important;
}

.p_contact_link:hover {
    cursor: pointer;
    color: #ee8f9b !important;
}

.btn_sticky_go_to_top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #ee2642 !important;
    color: #fff;
    text-align: center;
    z-index: 1040;
    box-shadow: 0 0 5px #da4b5e;
}

.btn_sticky_go_to_top:hover {
    background-color: #df2942 !important;
    color: white;
    box-shadow: 0 0 5px #995962;
}

.btn_sticky {
    position: fixed;
    top: 00%;
    right: 10px;
    background-color: #ee2642 !important;
    color: #fff;
    text-align: center;
    z-index: 1040;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0 0 5px #ee8f9b;
}

.btn_sticky:hover {
    background-color: #df2942 !important;
    color: white;
    box-shadow: 0 0 5px #995962;
}

.modal-header .btn-close {
    padding: unset !important;
    margin: unset !important;
}

.logo_without_border_design {
    position: absolute;
    height: 100px;
    border: 1px solid pink;
    border-radius: 20px;
    z-index: 9;
    transition: height 0.5s;
}

.navbar-toggler {
    margin-left: auto;
}

.nav_link {
    cursor: pointer;
}

.nav_link:hover, .nav-link:hover {
    color: #ee2642 !important
}

.btn_dhruva {
    background-color: #ee2642 !important;
    color: #fff;
    text-align: center;
}

.btn_dhruva:hover {
    background-color: #df2942 !important;
    color: white;
}

.img_registration {
    display: inline-block;
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

.div_form_card {
    vertical-align: middle;
    display: table;
    margin: 0 auto;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px;
    position: absolute;
}

.form_card {
    width: 300px;
    margin: 0 auto;
    box-shadow: 0 0.5rem 3rem rgba(0, 0, 0);
}

.dropdown-menu {
    background-color: #fff0f0;
    ;
}

.img_registration_banner {
    width: 100%;
    object-fit: cover;
}

.section_vendor_form, .section_sponsor_form, .section_fashionModel_form, .section_fashionDesigner_form {
    padding-bottom: 25px;
}

.card_form_registration {
    background-color: rgba(255, 255, 255, 0.25);
    border: unset;
}

.modal .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
}

.modal-content {
    background: url(./Media/bg_flowers.png);
    background-color: white;
    background-size: 100%;
}

.btn_expo_know_more {
    box-shadow: 0 0 20px white;
    font-size: large;
}

.footer_instagram_icon {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.footer_instagram_icon:hover {
    color: #ee8f9b !important;
}

.footer_facebook_icon {
    font-size: 20px;
    margin-right: 10px;
    cursor: pointer;
}

.footer_facebook_icon:hover {
    color: #ee8f9b !important;
}

.footer_li {
    margin-top: 5px;
}

.footer_ul {
    list-style-type: square;
}

.p_odm_link {
    cursor: pointer;
    color: #ff6c00;
}

.p_odm_link:hover {
    color: #ff974d;
}

.btn_sticky_call {
    position: fixed;
    bottom: 75px;
    left: 20px;
    background-color: #f17b21;
    color: #FFF;
    text-align: center;
    z-index: 1040;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25%;
    font-size: 20px;
    padding: 10px;
}

.btn_sticky_whatsapp {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: green;
    color: #FFF;
    text-align: center;
    z-index: 1040;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border-radius: 25%;
    font-size: 20px;
    padding: 10px;
}